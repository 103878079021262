/**
 * @param arr - vstupni signal
 * @param A - maximum signalu
 * @param T - perioda
 * @param p - posun (v sekundach)
 * @param d - strida (duty cycle)
 */

const obdelnikRovnice = (arr: number[]) =>
  arr.map((x) => (x < 200 && x > -200 ? 100 : 0))

const trojuhelnikRovnice = (arr: number[]) =>
  arr.map((x) => {
    if (0 >= x && x > -100) return x + 100
    if (0 < x && x <= 100) return 100 - x
    return 0
  })

const signal = (arr: number[]) =>
  arr.map((x) => {
    if (0 >= x && x > -100) return x + 100
    if (0 < x && x <= 200) return -0.5 * x + 100
    return 0
  })

export const namapujVstupNaRovnici = (input: string) => {
  switch (input) {
    case 'obdelnik':
      return obdelnikRovnice
    case 'trojuhelnik':
      return trojuhelnikRovnice
    case 'signal':
      return signal
    default:
      return obdelnikRovnice
  }
}
