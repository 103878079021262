// pole obsahujici vsechny druhy signalu
export const Rovnice = ['obdelnik', 'trojuhelnik', 'signal'] as const

// mapa hodnoty na text tlacitka
export const mapaTlacitek = {
  obdelnik: 'Obdélník',
  trojuhelnik: 'Trojúhelník',
  signal: 'Nesymetrický signál',
}

// funkce pro vypocet nahodneho cisla, jenz je vyuzita pri generaci sumoveho signalu
export const ziskejNahodneCeleCislo = (min: number, max: number) =>
  Math.random() * (max - min) + min

export type TypRovnice = typeof Rovnice[number]
