import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables,
  Tooltip,
} from 'chart.js'
import { useRef, useState } from 'react'
import { Chart } from 'react-chartjs-2'

import './App.css'

import { namapujVstupNaRovnici } from './knihovna/rovnice'
import { mapaTlacitek, Rovnice } from './knihovna/utility'
import { Teorie } from './komponenty/Teorie'
import ZmenaHodnoty from './komponenty/ZmenaHodnoty'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineElement,
  PointElement,
  ...registerables,
)

const vytvorPoleCelychCisel = (delka: number) => Array.from(Array(delka).keys())
export const C = 10

const MAXIMALNI_DELKA_GRAFU = 2500
export default function App() {
  //vytvoreni zaporne casti rozsahu grafu
  const maxRozliseniZaporne = vytvorPoleCelychCisel(MAXIMALNI_DELKA_GRAFU)
    .map((x) => -x)
    .reverse()
    .slice(0, -1)

  // vytvoreni rozsahu grafu
  const maxRozliseni = maxRozliseniZaporne.concat(
    vytvorPoleCelychCisel(MAXIMALNI_DELKA_GRAFU),
  )

  const [amplituda, nastavAmplitudu] = useState(0.5)

  const [meritko, nastavMeritko] = useState(1)

  const [rovnice, nastavRovnici] = useState('sinus')
  const [posun, nastavPosun] = useState(0)
  const rozsah = { min: -1000, max: 999 }

  const vstupniSignal = namapujVstupNaRovnici(rovnice)(
    maxRozliseni.map((x) => meritko * x - posun),
  ).map((x) => (x * amplituda) / 10)

  const zpracujZmenuRovnice = (nazev: string) => {
    nastavRovnici(nazev)
  }

  const zpracujZmenuAmplitudy = (A: string) => {
    const novaAmplituda = Number.parseFloat(A)
    if (rovnice !== 'sum') {
      nastavAmplitudu(novaAmplituda)
    }
  }

  const zpracujZmenuMeritka = (T: string) => {
    const noveMeritko = Number.parseFloat(T)
    nastavMeritko(noveMeritko)
    // omezeni krajnich hodnot periody
  }

  const zpracujZmenuPosunu = (p: string) => {
    const novyPosun = Number.parseFloat(p)
    nastavPosun(novyPosun)
  }

  const data = {
    labels: maxRozliseni,
    datasets: [
      {
        type: 'line' as const,
        backgroundColor: 'black',
        data: vstupniSignal.map((x) => x / 10),
        borderColor: 'black',
        borderWidth: 2,
      },
    ],
  }

  // nastaveni grafu
  const moznostiGrafu = {
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      y: {
        min: -1,
        max: 1,
        ticks: {
          stepSize: 500,
          font: {
            size: 18,
          },
          color: 'black',
        },
        title: {
          display: true,
          text: 'p(t)',
          font: {
            size: 22,
            style: 'italic',
          },
          color: 'black',
        },
      },
      x: {
        min: rozsah.min,
        max: rozsah.max,
        title: {
          display: true,
          text: 't',
          font: {
            size: 22,
            style: 'italic',
          },
          color: 'black',
        },
        ticks: {
          autoskip: true,
          autoSkipPadding: 190,
          color: 'black',
          font: {
            size: 18,
          },
        },
      },
    },
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const chartRef = useRef<ChartJS>(null)

  return (
    <>
      <h1>Posunutí, zesílení a změna měřítka</h1>
      <div className="chart-div">
        <Chart
          ref={chartRef}
          type="line"
          data={data}
          options={moznostiGrafu}
          height="100%"
          margin-left={'40px'}
        />
      </div>
      <div className="btn-div">
        {Rovnice.map((eq) => (
          <button
            key={eq}
            className="btn"
            style={{
              backgroundColor: eq === rovnice ? '#59B1B7' : '#4FAEB5',
            }}
            onClick={(e) => zpracujZmenuRovnice(e.currentTarget.value)}
            value={eq}
          >
            {mapaTlacitek[eq]}
          </button>
        ))}
      </div>

      <div>
        <br />
        <div className="theory-div">
          <div>
            <h3>Posun: {posun}</h3>
            <p>
              <input
                type="range"
                className="slider-green"
                value={posun}
                max={1000}
                min={-1000}
                step="1"
                onChange={(e) => zpracujZmenuPosunu(e.currentTarget.value)}
              ></input>
            </p>
            <h3>Zesílení: {amplituda.toFixed(2)}</h3>
            <p>
              <ZmenaHodnoty
                hodnota={amplituda}
                className="slider-red"
                zpracujZmenuHodnoty={zpracujZmenuAmplitudy}
              />
            </p>
          </div>

          <div>
            <h3>Měřítko: {meritko.toFixed(2)}</h3>
            <p>
              <input
                type="range"
                value={meritko}
                className="slider-blue"
                min="-2"
                step="0.01"
                max="2"
                onChange={(e) => zpracujZmenuMeritka(e.currentTarget.value)}
              ></input>
            </p>
          </div>
        </div>
        <div>
          <Teorie />
        </div>
        <hr style={{ marginLeft: '7.5%', marginRight: '7.5%' }} />
        <p style={{ fontSize: '13px', color: '#e6e6e6', marginLeft: '7.5%' }}>
          © 2023 Dominik Kuře, Pavel Rajmic, Vysoké učení technické v Brně
        </p>
      </div>
    </>
  )
}
